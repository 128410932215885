<template>
<!--   <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/jab?key=dsds">Jab</router-link> |
    <router-link to="/register?inviteCode=JHdb7">注册</router-link> |
    <router-link to="/login?token=JHdbHjdnhjd7">登录</router-link>
  </nav>
 -->  
  <router-view/>
  <van-tabbar v-if="navRouteShow" route>
    <van-tabbar-item replace to="/home" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item replace to="/star" icon="star-o">升星</van-tabbar-item>
    <van-tabbar-item replace to="/team" icon="cluster-o">团队</van-tabbar-item>
    <van-tabbar-item replace to="/my" icon="user-circle-o">我的</van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  data() {
    return {
      navRouteShow:false,
    }
  },
  setup() {
    // console.log('app.vue navRouteShow', navRouteShow);
    return {
      // navRouteShow,
    }
  },
  mounted(){
    const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));

    if(loginInfo == null){
      this.navRouteShow = false;
      // this.$router.push('/login');
    }else{
      // this.$router.replace('/login');
      this.navRouteShow = true;
      // this.$router.go(0);this.$route.name
      // if(this.$route.name='')
      // this.$router.push(this.$route.path);
    }
    // console.log('this.$router', this.$route.name);
  } 
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*font-size: 16px;*/
}
/*
nav {
  padding: 30px;
}*/

nav a {
  font-weight: bold;
  /*color: #2c3e50;*/
}
/*
nav a.router-link-exact-active {
  color: #42b983;
}*/
.van-cell-group__title {
  text-align: left;
}
</style>
