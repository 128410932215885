import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
    // name: 'index',
    // component: () => import('../views/IndexView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {path: '/jab', name: 'jab', component: () => import('../views/JabView.vue')},
  {path: '/invite', name: 'invite', meta: {auth: false, title: '大树社交新零售'}, component: () => import('../views/InviteView.vue')},
  {path: '/register', name: 'register', meta: {auth: false, title: '大树社交新零售'}, component: () => import('../views/RegisterView.vue')},
  {path: '/login', name: 'login', meta: {auth: false, title: '大树社交新零售'}, component: () => import('../views/LoginView.vue')},
  {path: '/home',name: 'home', meta: {auth: true, title: '大树社交新零售'}, component: () => import('../views/HomeView.vue')},
  {path: '/my', name: 'my', meta: {auth: true, title: '大树社交新零售'},redirect:'/my/my',
    children:[
      // {path: '/my/invite', name: 'invite', component: () => import('../views/InviteView.vue'),props: true,meta: { title: "大树社交新零售" }},
      {path: '/my/my', name: 'my', component: () => import('../views/MyView.vue')},
      // {path: '/my/business', name: 'business', component: () => import('../views/Index/Business.vue')},
    ]
  },
  {path: '/team', name: 'team', meta: {auth: true, title: '大树社交新零售'}, component: () => import('../views/TeamView.vue')},
  {path: '/good', name: 'good', meta: {auth: true, title: '大树社交新零售'}, component: () => import('../views/GoodView.vue')},
  {path: '/star', name: 'star', meta: {auth: true, title: '大树社交新零售'}, component: () => import('../views/StarView.vue')},
  {path: '/edit', name: 'edit', meta: {auth: true, title: '大树社交新零售'}, component: () => import('../views/EditView.vue')},
  // {path: '/invite', name: 'invite', meta: {title: '大树社交新零售'}, component: () => import('../views/InviteView.vue')},
  // {path: '/index', name: 'index', component: () => import('../views/IndexView.vue')},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 

// router.beforeEach(async (to, from, next) => {
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }

  //路由权限判断
  const loginInfo = localStorage.getItem("loginInfo");
    // console.log('index.js loginInfo', loginInfo);
  if (to.meta.auth && !loginInfo) {
      return router.replace({
        name: 'login'
      })
  }
  // next();
});
export default router
