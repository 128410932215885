import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

import 'vant/lib/index.css';
import Vant from 'vant'; app.use(Vant);

// import { Button } from 'vant'; app.use(Button);
// import { Loading } from 'vant'; app.use(Loading);
// import { Toast } from 'vant'; app.use(Toast);
// import { Cell, Form, Field, CellGroup } from 'vant';
// app.use(Form);
// app.use(Cell);
// app.use(Field);
// app.use(CellGroup);
app.use(router).mount('#app');

// createApp(App).use(Button);
// createApp(App).use(router).mount('#app')